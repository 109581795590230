import * as React from 'react';

import { Heading } from '../ui/text/Heading';
import { Paragraph } from '../ui/text/Paragraph';
import { Button } from '../ui/button/Button';
import { ButtonGroup } from '../ui/button/ButtonGroup';
import styled from 'styled-components';
import ParagraphTitle from '../ui/text/ParagraphTitle';

export const AboutValuationText: React.FC = () => {
  return (
    <>
      <Heading tag="h2">Få verdivurdering av din eiendom</Heading>
      <Heading tag="h4">E-Takst fra Eiendomsverdi</Heading>
      <Paragraph>
        E-Takst fra Eiendomsverdi er din PrivatMeglers verdivurdering for bruk
        ved låneopptak eller refinansiering i bank. I tillegg til selve
        E-taksten på èn side, vil banken kunne se meglers konkrete vurdering,
        datagrunnlag og bilder. Dersom kjøper og selger har funnet hverandre
        direkte uten megler, og kun ønsker bistand til kontrakt og oppgjør, vil
        det samme produktet automatisk være inkludert i megleroppdraget.
      </Paragraph>
      <Heading tag="h4">"Meglers verdivurdering"</Heading>
      <Paragraph>
        Meglers Verdivurdering fra Eiendomsverdi er din PrivatMeglers
        verdivurdering for bruk i.f.m. salg eller kjøp av eiendom, og skal være
        vedlagt alle salgsoppgaver. Dokumentet på 4 sider vil gi potensielle
        kjøpere innsyn i meglers konkrete vurdering og datagrunnlag. Her vil en
        kunne se hvordan megler har kommet frem til verdien, hvilke
        sammenlignbare eiendommer som er solgt, hvilke som er for salg i
        markedet akkurat nå m.m.
      </Paragraph>
      <Heading tag="h3">Forventninger</Heading>
      <Paragraph>
        Først når verdivurderingen er foretatt, kan det være aktuelt å utføre
        oppgraderinger som kan påvirke verdien. Som eiendomsselger får du raskt
        et innblikk i hva markedet kan være villig til å betale, og dermed
        avstemme om dette harmonerer med dine egne forventninger.
      </Paragraph>
      <Heading tag="h3">Vår tilnærming</Heading>
      <Paragraph>
        Når vi verdivurderer din eiendom, skjer det gjennom en befaring sammen
        med deg som selger. Du viser frem eiendommen, presenterer de ulike
        rommene og forklarer hva som eventuelt er utført av oppgraderinger siden
        du flyttet inn. I tillegg til befaringen vil megleren i forkant innhente
        statistisk informasjon om eiendomspriser i området, og naturligvis ta
        hensyn til utviklingen i markedet.
      </Paragraph>
      <Heading tag="h3">Rådgivende rolle</Heading>
      <Paragraph>
        I forbindelse med befaringen har megleren også en rådgivende rolle som
        skal fremheve særegenheter ved nettopp din eiendom, og hva du eventuelt
        kan gjøre for at eiendommen blir enda mer attraktiv hos potensielle
        kjøpere. Vår klare anbefaling er at du ikke setter i gang arbeid på
        eiendommen før du har gjennomført befaringen med megler. Basert på
        erfaring er det i de fleste tilfeller bare mindre oppgraderinger som
        skal til for å få eiendommen til å fremstå som den fortjener.
      </Paragraph>
      <Heading tag="h3">Faktorer i verdivurderingen</Heading>
      <Paragraph>
        Det er flere faktorer som gjenspeiles i en verdivurdering – den
        representerer summen av totaliteten. I dette ligger beliggenhet,
        standard på eiendommen, antall rom og planløsninger, nabolag, nærhet til
        skoler, barnehager og aktivitetstilbud, kollektivtilbud, garasje- og
        parkeringsmuligheter, uteområder og eventuelle fellesarealer, for å
        nevne noe.
      </Paragraph>
      <ButtonGroup>
        <Button
          type="primary"
          colorTheme="gold"
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.scroll(0, 0);
            }
          }}
        >
          Kontakt megler
        </Button>
      </ButtonGroup>
    </>
  );
};
